export default Object.freeze({
  IsActivated: 'IsActivated',
  Success: 'Success',
  InReview: 'IN-REVIEW',
  OnboardingRequired: 'Hello! You need to complete your Onboarding to access this feature',
  Failed: 'Failed',
  LIMIT_EXCEEDED: 'Account limit is exceeded for this transaction, kindly contact the Admin',
  TokenSent: 'Token Sent',
  RouteParams: 'RouteParams',
  business: 'business',
  walletBalance: 'walletBalance',
  TransactionsSummary: 'TransactionsSummary',
  WalletTransactions: 'WalletTransactions',
  WeekSummary: 'WeekSummary',
  TransferPayload: 'TRANSFERPAYLOAD',
  Banks: 'Banks',
  Sent: 'Sent',
  INVALID_PIN: 'Invalid Pin',
  SET_UP_PIN: 'Please set your pin to use this feature',
  LOW_WALLET_BALANCE: 'Account balance is low, kindly top up',
  STATUS: {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    VOID: 'VOID',
    FAILED: 'FAILED',
    PAID: 'PAID',
    IGNORE: 'IGNORED',
    IN_REVIEW: 'IN REVIEW',
    REVIEWED: 'REVIEWED',
    PARTIALLY_REVIEWED: 'PARTIALLY REVIEWED',
    REQUEST_CHANGE: 'REQUEST CHANGE',
    PROCESSING: 'PROCESSING',
    REVERSED: 'REVERSED',
  },
  TRANSACTION_STATUS: {
    PAID: 'FULLY-PAID',
    VOID: 'VOID',
    UNPAID: 'UNPAID',
    PARTIALLY_PAID: 'PARTIALLY-PAID',
  },
  MissingWithdrawalAccount: 'Kindly Setup Your Withdrawal Account',
  RoutePath: 'RoutePath',
  Routes: {
    Landing: '/',
    Home: '/home',
    Login: '/auth/login',
    SignUp: '/auth/signup',
    ForgetPassword: '/auth/forgotPassword',
    Dashboard: '/dashboard',
    Otp: '/auth/otp',

    BlogLanding: '/blog',
    BlogSinglePost: '/blog/:id',
    Contact: '/contact',
    About: '/about',
    Faqs: '/faqs',
    Terms: '/terms',
    Privacy: '/privacy-policy',
    Blog1: '/blog/benefits-of-digital-business-management',
    Blog2: '/blog/business-management-solutions-streamlining-operations-and-driving-success',
    Blog3: '/blog/streamline-your-payroll-management-with-mima-the-ultimate-solution-for-business-owners',
    Sitemap: '/sitemap.xml',
    PartnerHome: '/partnership',
    PartnerDashboard: '/partnership/dashboard',
    PartnerSignUp: '/partnership/signup',
    PartnerLogin: '/partnership/login',
    PartnerForgetPassword: '/partnership/forgotPassword',
    PartnerForgetPasswordChange: '/partnership/forgotPassword/change',
    PartnerOtp: '/partnership/otp',
    AndroidApp: 'https://play.google.com/store/apps/details?id=com.mima.fash',
    IosApp: 'https://apps.apple.com/ng/app/fash-creatives/id6484272354',
    Competition: '/competition',
    Register: '/competition/register',
    Contestants: '/competition/contestants',
    TermsAndConditions: '/competition/termsAndConditions',
    VoteTimeline: '/competition/voteTimeline',
    PastWinners: '/competition/past-winners'
  },
  IsOnBoardingDone: 'IsOnBoardingDone',
  OtpType: {
    FORGETPASSWORD: 'FORGETPASSWORD',
    ONBOARDING: 'ONBOARDING',
    BVNONBOARDING: 'BVNONBOARDING',
    PARTNERSIGNUP: 'PARTNERSIGNUP',
    PARTNERFORGETPASSWORD: 'PARTNERFORGETPASSWORD',
  },
  DISCOUNTS: {
    ONE_YEAR: 'oneYearDiscountPer',
    SIX_MONTHS: 'sixMonthsDiscountPer',
    THREE_MONTHS: 'threeMonthsDiscountPer',
  },
});
