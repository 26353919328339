import { Data, ICompetitionDTO, IParticipant } from '@/interfaces';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { string } from 'zod';
export default class CompetitionStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'CompetitionStore',
      properties: [
        'competitions',
        'competitionId',
        'participants',
        'competitionStage',
        'winners',
        'selectedPastCompetition',
      ],
    });
  }

  // Store state

  competitions: ICompetitionDTO[] = [];
  competitionId: string = '';
  competitionStage: string = '';
  participants: IParticipant[] = [];
  winners: any;
  selectedPastCompetition: Data = {};


  // Method to set store properties

  setCompetitions = async (competitions: ICompetitionDTO[]) => {
    runInAction(async () => {
      this.competitions = competitions;
    });
  };
  setCompetitionId = async (competitionId: string) => {
    runInAction(async () => {
      this.competitionId = competitionId;
    });
  };
  setCompetitionStage = async (competitionStage: string) => {
    runInAction(async () => {
      this.competitionStage = competitionStage;
    });
  };
  setParticipants = async (participants: IParticipant[]) => {
    runInAction(async () => {
      this.participants = participants;
    });
  };
  setWinners = async (winners: any) => {
    runInAction(async () => {
      this.winners = winners;
    });
  };
  setSelectedPastCompetition = async (selectedPastCompetition: Data) => {
    runInAction(async () => {
      this.selectedPastCompetition = selectedPastCompetition;
    });
  };
  
  

  // Reset method to clear the store
  reset = () => {
    this.competitions = [];
    this.competitionId = '';
    this.participants = [];
    this.winners = [];
    this.selectedPastCompetition = {};
  };
}
