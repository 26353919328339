'use client';

import Image, { StaticImageData } from 'next/image';
import { useState } from 'react';
import { GrNext, GrPrevious, GrClose } from 'react-icons/gr';
import styles from '@/assets/styles/components/ImageCarousel.module.scss';
import { myLoader } from '@/utils/utils';

interface ImageCarouselProps {
  images: StaticImageData[] | string[];
  width?: number;
  height?: number;
}

const ImageCarousel = ({ images, width = 250, height = 250 }: ImageCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const openModal = (index: number) => {
    setCurrentIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={styles.carousel}>
        <Image
          loader={myLoader}
          src={images[currentIndex]}
          alt={`Crochet photo ${currentIndex + 1}`}
          style={{ objectFit: 'contain', width, height, borderRadius: '8px', cursor: 'pointer' }}
          width={width}
          height={height}
          onClick={() => openModal(currentIndex)}
        />

        <GrPrevious className={styles.carousel_arrowLeft} onClick={handlePrev} />
        <GrNext className={styles.carousel_arrowRight} onClick={handleNext} />
      </div>

      {isModalOpen && (
        <div
          className={styles.modalOverlay}
          onClick={closeModal}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            background: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10000,
          }}
        >
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
            style={{
              position: 'relative',
              background: 'rgba(0, 0, 0, 0.9)',
              padding: '10px',
              borderRadius: '10px',
            }}
          >
            <GrClose
              className={styles.modalClose}
              onClick={closeModal}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                fontSize: '24px',
                color: 'white',
              }}
            />
            <Image
              loader={myLoader}
              src={images[currentIndex]}
              alt={`Expanded crochet photo ${currentIndex + 1}`}
              width={window.innerWidth * 0.8}
              height={window.innerHeight * 0.8}
              style={{
                objectFit: 'contain',
                borderRadius: '8px',
                display: 'block',
                maxWidth: '80vw',
                maxHeight: '80vh',
              }}
            />
            <GrPrevious
              className={styles.modalArrowLeft}
              onClick={handlePrev}
              style={{
                position: 'absolute',
                left: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                fontSize: '24px',
                color: 'white',
              }}
            />
            <GrNext
              className={styles.modalArrowRight}
              onClick={handleNext}
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                fontSize: '24px',
                color: 'white',
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ImageCarousel;
